import { render, staticRenderFns } from "./signage.vue?vue&type=template&id=51ea80ae&"
import script from "./signage.vue?vue&type=script&lang=ts&"
export * from "./signage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports