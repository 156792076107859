export const HollanderResults = () => import('../../components/HollanderResults.vue' /* webpackChunkName: "components/hollander-results" */).then(c => wrapFunctional(c.default || c))
export const InterchangeSearch = () => import('../../components/InterchangeSearch.vue' /* webpackChunkName: "components/interchange-search" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PartSearch = () => import('../../components/PartSearch.vue' /* webpackChunkName: "components/part-search" */).then(c => wrapFunctional(c.default || c))
export const QuoteForm = () => import('../../components/QuoteForm.vue' /* webpackChunkName: "components/quote-form" */).then(c => wrapFunctional(c.default || c))
export const Tire = () => import('../../components/Tire.vue' /* webpackChunkName: "components/tire" */).then(c => wrapFunctional(c.default || c))
export const TireSearch = () => import('../../components/TireSearch.vue' /* webpackChunkName: "components/tire-search" */).then(c => wrapFunctional(c.default || c))
export const Title = () => import('../../components/Title.vue' /* webpackChunkName: "components/title" */).then(c => wrapFunctional(c.default || c))
export const ValidatedFormGroup = () => import('../../components/ValidatedFormGroup.vue' /* webpackChunkName: "components/validated-form-group" */).then(c => wrapFunctional(c.default || c))
export const VehicleAlerts = () => import('../../components/VehicleAlerts.vue' /* webpackChunkName: "components/vehicle-alerts" */).then(c => wrapFunctional(c.default || c))
export const VehicleSearch = () => import('../../components/VehicleSearch.vue' /* webpackChunkName: "components/vehicle-search" */).then(c => wrapFunctional(c.default || c))
export const Xselect = () => import('../../components/xselect.vue' /* webpackChunkName: "components/xselect" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
