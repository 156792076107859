












import Vue from 'vue'
export default Vue.extend({
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    statusCode() {
      return (this.error && (this.error as any).statusCode) || 500
    },
    message() {
      return (this.error as any).message
    },
  },
})
