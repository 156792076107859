






import Vue from 'vue'

export default Vue.extend({
  name: 'SignageLayout',
  data() {
    return {}
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.u-pullandsave.com' + this.$route.path,
        },
      ],
    }
  },
})
