import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faFacebookSquare as freeFabFaFacebookSquare
} from '@fortawesome/free-brands-svg-icons'

import {
  faCaretDown as proFasFaCaretDown,
  faCaretLeft as proFasFaCaretLeft,
  faCaretRight as proFasFaCaretRight,
  faSun as proFasFaSun,
  faSnowflake as proFasFaSnowflake,
  faPhone as proFasFaPhone,
  faFax as proFasFaFax,
  faEnvelope as proFasFaEnvelope,
  faMapMarker as proFasFaMapMarker,
  faMapMarkerAlt as proFasFaMapMarkerAlt,
  faCheck as proFasFaCheck,
  faWrench as proFasFaWrench,
  faBars as proFasFaBars,
  faCogs as proFasFaCogs,
  faList as proFasFaList,
  faCar as proFasFaCar,
  faUsdCircle as proFasFaUsdCircle,
  faFilter as proFasFaFilter
} from '@fortawesome/pro-solid-svg-icons'

import {
  faTire as proFadFaTire,
  faSteeringWheel as proFadFaSteeringWheel
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  freeFabFaFacebookSquare,
  proFasFaCaretDown,
  proFasFaCaretLeft,
  proFasFaCaretRight,
  proFasFaSun,
  proFasFaSnowflake,
  proFasFaPhone,
  proFasFaFax,
  proFasFaEnvelope,
  proFasFaMapMarker,
  proFasFaMapMarkerAlt,
  proFasFaCheck,
  proFasFaWrench,
  proFasFaBars,
  proFasFaCogs,
  proFasFaList,
  proFasFaCar,
  proFasFaUsdCircle,
  proFasFaFilter,
  proFadFaTire,
  proFadFaSteeringWheel
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
