








































































































































































































































































































































































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      showWeatherMessage: new Date() < new Date('2022-12-24 00:00:00'),
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.u-pullandsave.com' + this.$route.path,
        },
      ],
    }
  },
})
