import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _462555fc = () => interopDefault(import('../pages/cars-for-sale/index.vue' /* webpackChunkName: "pages/cars-for-sale/index" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _6d0969a7 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _237df571 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _13a812e2 = () => interopDefault(import('../pages/price-list/index.vue' /* webpackChunkName: "pages/price-list/index" */))
const _49db495b = () => interopDefault(import('../pages/sell-your-car.vue' /* webpackChunkName: "pages/sell-your-car" */))
const _5b80f3f8 = () => interopDefault(import('../pages/tire-shop/index.vue' /* webpackChunkName: "pages/tire-shop/index" */))
const _1d93a6fe = () => interopDefault(import('../pages/used-car-parts.vue' /* webpackChunkName: "pages/used-car-parts" */))
const _4ac624b2 = () => interopDefault(import('../pages/used-car-parts-mason.vue' /* webpackChunkName: "pages/used-car-parts-mason" */))
const _3bee065a = () => interopDefault(import('../pages/used-car-parts-pontiac.vue' /* webpackChunkName: "pages/used-car-parts-pontiac" */))
const _2ec71a20 = () => interopDefault(import('../pages/cars-for-sale/for-sale-signage.vue' /* webpackChunkName: "pages/cars-for-sale/for-sale-signage" */))
const _917885ec = () => interopDefault(import('../pages/price-list/price-list-signage.vue' /* webpackChunkName: "pages/price-list/price-list-signage" */))
const _4e8b167b = () => interopDefault(import('../pages/promotions/push-pull-drag-05182024.vue' /* webpackChunkName: "pages/promotions/push-pull-drag-05182024" */))
const _336dc649 = () => interopDefault(import('../pages/notifications/_phoneNumber.vue' /* webpackChunkName: "pages/notifications/_phoneNumber" */))
const _7d48815a = () => interopDefault(import('../pages/shortcode/_shortCode.vue' /* webpackChunkName: "pages/shortcode/_shortCode" */))
const _4b38f85c = () => interopDefault(import('../pages/vehicles/_id.vue' /* webpackChunkName: "pages/vehicles/_id" */))
const _6af911ca = () => interopDefault(import('../pages/cars-for-sale/_yearMakeModel/_id.vue' /* webpackChunkName: "pages/cars-for-sale/_yearMakeModel/_id" */))
const _711fdbb9 = () => interopDefault(import('../pages/tire-shop/_width/_aspectRatio/_diameter/_store/index.vue' /* webpackChunkName: "pages/tire-shop/_width/_aspectRatio/_diameter/_store/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cars-for-sale",
    component: _462555fc,
    name: "cars-for-sale"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/how-it-works",
    component: _6d0969a7,
    name: "how-it-works"
  }, {
    path: "/notifications",
    component: _237df571,
    name: "notifications"
  }, {
    path: "/price-list",
    component: _13a812e2,
    name: "price-list"
  }, {
    path: "/sell-your-car",
    component: _49db495b,
    name: "sell-your-car"
  }, {
    path: "/tire-shop",
    component: _5b80f3f8,
    name: "tire-shop"
  }, {
    path: "/used-car-parts",
    component: _1d93a6fe,
    name: "used-car-parts"
  }, {
    path: "/used-car-parts-mason",
    component: _4ac624b2,
    name: "used-car-parts-mason"
  }, {
    path: "/used-car-parts-pontiac",
    component: _3bee065a,
    name: "used-car-parts-pontiac"
  }, {
    path: "/cars-for-sale/for-sale-signage",
    component: _2ec71a20,
    name: "cars-for-sale-for-sale-signage"
  }, {
    path: "/price-list/price-list-signage",
    component: _917885ec,
    name: "price-list-price-list-signage"
  }, {
    path: "/promotions/push-pull-drag-05182024",
    component: _4e8b167b,
    name: "promotions-push-pull-drag-05182024"
  }, {
    path: "/notifications/:phoneNumber",
    component: _336dc649,
    name: "notifications-phoneNumber"
  }, {
    path: "/shortcode/:shortCode?",
    component: _7d48815a,
    name: "shortcode-shortCode"
  }, {
    path: "/vehicles/:id?",
    component: _4b38f85c,
    name: "vehicles-id"
  }, {
    path: "/cars-for-sale/:yearMakeModel?/:id?",
    component: _6af911ca,
    name: "cars-for-sale-yearMakeModel-id"
  }, {
    path: "/tire-shop/:width?/:aspectRatio/:diameter?/:store",
    component: _711fdbb9,
    name: "tire-shop-width-aspectRatio-diameter-store"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
