import { shouldPolyfill as polyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as polyfillPlurals } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as polyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as polyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'

async function polyfill() {
  if (polyfillLocale()) {
    console.log('Polyfill for locale loading...')
    await import('@formatjs/intl-locale/polyfill')
  }
  if (polyfillPlurals()) {
    console.log('Polyfill for plurals loading...')
    await import('@formatjs/intl-pluralrules/polyfill')
  }
  if (polyfillCanonicalLocales()) {
    console.log('Polyfill for canonical locales loading...')
    await import('@formatjs/intl-getcanonicallocales/polyfill')
  }
  if (polyfillNumberFormat()) {
    console.log('Polyfilling....')
    await import('@formatjs/intl-numberformat/polyfill')
    console.log('Importing locales')
    await import('@formatjs/intl-numberformat/locale-data/en')
    console.log('Done.')
  }
}

polyfill()
